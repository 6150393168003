<template>
  <footer class="footer center" id="footer">
    <div class="footer__copy">© 2015 - {{ year }}</div>
    <router-link to="/" class="footer__link">EarnForex.</router-link>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      year: "",
    };
  },
  created() {
    this.year = new Date().getFullYear();
    this.clearLocalStorage();
  },
  methods: {
    clearLocalStorage() {
      window.onbeforeunload = () => {
        localStorage.removeItem("isAuth");
      };
    },
  },
};
</script>
