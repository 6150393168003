<template>
  <div class="wrapper">
    <Header />

    <div class="center container">
      <main class="main" id="main">
        <div class="info-section">
          <div class="info-section__header">
            <div class="info-section__header-title">Screenshots of brokers</div>
          </div>
          <div class="info-section__content">
            <Screenshots />
          </div>
        </div>
      </main>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer";
import Screenshots from "@/components/settings/Screenshots";

export default {
  name: "Settings",
  components: {
    Header,
    Footer,
    Screenshots,
  },
};
</script>

<style lang="scss">
.info-section {
  &__header {
    border-bottom: 1px solid var(--accent);
    margin-bottom: 20px;
    &-title {
      display: inline-block;
      height: 52px;
      text-transform: capitalize;
      font-size: 24px;
      line-height: 50px;
      border-bottom: 3px solid var(--accent);
    }
  }
  &__content {
    border-radius: 2px;
    padding: 20px 40px 20px 20px;
    border: 1px solid var(--borders-color);
    margin-bottom: 35px;
  }
}
</style>
