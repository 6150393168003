<template>
  <div class="upd-screenshots">
    <button
      @click="updateScreenshots"
      :class="['btn', { '--disabled': loading }]"
    >
      {{ btnLabel }}
    </button>
    <div v-if="created" class="upd-screenshots__created">{{ created }}</div>
    <div v-if="!loading" class="upd-screenshots__info">
      <div>Brokers amount: {{ allScreenshotsInfo.total }}</div>
      <div>Finished: {{ allScreenshotsInfo.finished }}</div>
      <div>Success: {{ allScreenshotsInfo.success }}</div>
    </div>
    <a v-if="logLink" class="upd-screenshots__log" :href="logLink" download
      >Download log</a
    >
    <div v-if="errorMsg" class="upd-screenshots__error">{{ errorMsg }}</div>
    <div v-if="loading" class="upd-screenshots__loader">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>

    <div v-if="allScreenshotLogs.length" class="upd-screenshots__result">
      <div class="upd-screenshots__result-title">Logs:</div>
      <ul
        v-for="(log, index) in allScreenshotLogs"
        :key="index"
        class="upd-screenshots__result-list"
      >
        <li class="upd-screenshots__result-list-item">
          <span class="upd-screenshots__result-date">{{
            log["created_at"] | parseDate
          }}</span>
          <a
            :href="log['downloadLink']"
            download
            class="upd-screenshots__result-link"
            >download log</a
          >
        </li>
      </ul>
    </div>

    <div class="upd-screenshots__download-btn">
      <button @click="createArchive" class="btn">
        Create screenshot archive
      </button>
    </div>
    <div v-show="downloadTipVisibility" class="upd-screenshots__download-tip">
      The task to create the archive was successfully set!
      <br /><span>Please refresh the page in a few minutes.</span>
    </div>

    <div v-if="allScreenshotArchives.length" class="upd-screenshots__result">
      <div class="upd-screenshots__result-title">Archives:</div>
      <ul
        v-for="(arch, index) in allScreenshotArchives"
        :key="index"
        class="upd-screenshots__result-list"
      >
        <li class="upd-screenshots__result-list-item">
          <span class="upd-screenshots__result-date"
            >Link available at {{ arch["dateExpired"] }}</span
          >
          <a :href="arch['url']" download class="upd-screenshots__result-link"
            >download archive</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {
  screenshotsUpdateStatus,
  allScreenshotLogs,
  allArchiveScreenshots,
} from "@/components/graphQL/brokerQueries";
import {
  allBrokerScreenshots,
  createArchiveScreenshots,
} from "@/components/graphQL/brokerMutations";

export default {
  name: "Screenshots",
  created() {
    this.checkScreenshotUpdatingStatus();
    this.fetchAllScreenshotLogs();
    this.fetchAllArchiveScreenshots();

    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        this.stopScreenshotUpdating = false;
        this.checkScreenshotUpdatingStatus();
      } else {
        this.stopScreenshotUpdating = true;
      }
    });
  },
  filters: {
    parseDate(dateVal) {
      if (dateVal) {
        let date = new Date(dateVal);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })}`;
      }
      return false;
    },
  },
  data() {
    return {
      loading: true,
      errorMsg: null,
      created: null,
      logLink: null,
      btnLabel: "Update screenshots",
      stopScreenshotUpdating: false,
      allScreenshotsInfo: {
        total: null,
        finished: null,
        success: null,
      },
      allScreenshotLogs: [],
      downloadTipVisibility: false,
      allScreenshotArchives: [],
    };
  },
  methods: {
    async checkScreenshotUpdatingStatus() {
      if (this.stopScreenshotUpdating) return false;

      try {
        const { data } = await this.$apollo.query({
          query: screenshotsUpdateStatus,
          errorPolicy: "all",
          fetchPolicy: "no-cache",
        });

        if (data) {
          if (
            data.screenshotsUpdateStatus.finished ===
            data.screenshotsUpdateStatus.brokers
          ) {
            this.stopScreenshotUpdating = true;
            this.btnLabel = "Update screenshots";
            this.loading = false;
            this.allScreenshotsInfo.total =
              data.screenshotsUpdateStatus.brokers;
            this.allScreenshotsInfo.finished =
              data.screenshotsUpdateStatus.finished;
            this.allScreenshotsInfo.success =
              data.screenshotsUpdateStatus.success;
          } else {
            this.btnLabel = `In the process (${data.screenshotsUpdateStatus.finished}/${data.screenshotsUpdateStatus.brokers})`;

            setTimeout(() => {
              this.checkScreenshotUpdatingStatus();
            }, 3000);
          }
        }
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },

    async updateScreenshots() {
      this.created = null;
      this.errorMsg = null;
      this.loading = true;

      try {
        this.$apollo
          .mutate({
            mutation: allBrokerScreenshots,
            variables: {},
          })
          .then(({ data }) => {
            if (data.allBrokerScreenshots.success) {
              this.stopScreenshotUpdating = false;
              this.checkScreenshotUpdatingStatus();
            }
          })
          .catch((err) => {
            console.log(err);
            this.loading = true;
          });
      } catch (e) {
        this.loading = false;
        this.errorMsg = "Server error";
        console.log(e);
      }
    },

    async fetchAllScreenshotLogs() {
      try {
        const { data } = await this.$apollo.query({
          query: allScreenshotLogs,
          errorPolicy: "all",
          fetchPolicy: "no-cache",
        });

        if (data)
          this.allScreenshotLogs = data.allScreenshotLogs.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );
      } catch (err) {
        console.log(err);
      }
    },

    async fetchAllArchiveScreenshots() {
      try {
        const { data } = await this.$apollo.query({
          query: allArchiveScreenshots,
          errorPolicy: "all",
          fetchPolicy: "no-cache",
        });

        if (data) {
          this.allScreenshotArchives = data.allArchiveScreenshots;
        }
      } catch (err) {
        console.log(err);
      }
    },

    async createArchive() {
      try {
        this.$apollo
          .mutate({
            mutation: createArchiveScreenshots,
            variables: {},
          })
          .then(({ data }) => {
            if (data.createArchiveScreenshots) {
              this.downloadTipVisibility = true;
            }
          })
          .catch(() => {
            console.log("Error");
          });
      } catch (e) {
        this.errorMsg = "Server error";
        console.log(e);
      }
    },
  },
  beforeDestroy() {
    this.stopScreenshotUpdating = true;
  },
};
</script>

<style lang="scss">
.upd-screenshots {
  &__info {
    margin: 10px 0;
  }
  &__loader {
    margin: 10px 0;
    span {
      display: inline-block;
      height: 15px;
      width: 15px;
      background-color: #c7c7c7;
      &:nth-child(1) {
        animation: rotateX 2s 0.1s infinite
          cubic-bezier(0.65, 0.03, 0.735, 0.045);
      }
      &:nth-child(2) {
        animation: rotateX 2s 0.2s infinite
          cubic-bezier(0.65, 0.03, 0.735, 0.045);
      }
      &:nth-child(3) {
        animation: rotateX 2s 0.3s infinite
          cubic-bezier(0.65, 0.03, 0.735, 0.045);
      }
      &:nth-child(4) {
        animation: rotateX 2s 0.4s infinite
          cubic-bezier(0.65, 0.03, 0.735, 0.045);
      }
      &:nth-child(5) {
        animation: rotateX 2s 0.5s infinite
          cubic-bezier(0.65, 0.03, 0.735, 0.045);
      }
    }
  }
  &__created {
    margin: 10px 0;
    color: #717171;
    font-size: 13px;
  }
  &__error {
    margin: 10px 0;
    color: #ff2828;
    font-size: 13px;
  }
  &__log {
    border-bottom: 1px dashed currentColor;
    color: var(--accent);
    display: inline-block;
    margin: 10px 0;
  }
  &__result {
    margin-top: 20px;
    &-title {
      margin-bottom: 7px;
    }
    &-list {
      list-style: none;
      margin: 0;
      padding: 0;
      &-item {
        display: block;
        margin-bottom: 3px;
      }
    }
    &-date {
      margin-right: 10px;
      color: var(--secondary-text);
    }
    &-link {
      color: var(--accent);
    }
  }
  .btn {
    margin: 0;
  }
  &__download {
    &-btn {
      margin-top: 30px;
    }
    &-tip {
      margin: 10px 0;
      span {
        color: var(--secondary-text);
      }
    }
  }
}

@keyframes rotateX {
  0% {
    transform: rotateX(0deg);
  }
  50% {
    transform: rotateX(90deg) scale(0.5, 0.5);
    background: var(--accent);
  }
  100% {
    transform: rotateX(0deg);
  }
}
</style>
