<template>
  <div class="notification center" :class="type">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "notificationTop",
  props: {
    text: String,
    type: String,
    center: Boolean,
  },
};
</script>
